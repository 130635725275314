try{
    window.addEventListener('scroll', function () {
        let scrollTop = window.pageYOffset;

        if (scrollTop >= 100) {
            document.querySelector('#header').classList.add('minimize');
            document.querySelector('#image-desktop').style.zIndex = 200;
        } else {
            document.querySelector('#header').classList.remove('minimize');
            document.querySelector('#image-desktop').style.zIndex = 350;
        }
    });
}catch (e){

}

try {
    let tab = function () {
        let tabNav = document.querySelectorAll('.tab__item');
        let tabContent = document.querySelectorAll('.tabContent__item');
        let tabName;
        tabNav.forEach(item => {
            item.addEventListener('click', selectTabNav)
        });
        function selectTabNav() {
            tabNav.forEach(item => {
                item.classList.remove('tab__item-active');
            });
            this.classList.add('tab__item-active');
            tabName = this.getAttribute('data-tab-name');
            selectTabContent(tabName);
        }
        function selectTabContent(tabName) {
            tabContent.forEach(item => {
                item.classList.contains(tabName) ? item.classList.add('tabContent__item-active') : item.classList.remove('tabContent__item-active');
            });
        }
    };
    tab();

} catch(e){

}

try{
    let modal = document.querySelector("#modal"),
        modalClose = document.querySelector("#modal-close"),
        showModal = document.querySelectorAll(".show-modal");

    showModal.forEach((item) => {
        item.addEventListener("click", (e) => {
            e.preventDefault();
            document.querySelector("body").classList.add("body-fixed");
            modal.style.display = "flex";
        })
    });
    modalClose.addEventListener("click", (e) => {
        e.preventDefault();
        document.querySelector("body").classList.remove("body-fixed");
        modal.style.display = "";
    });


} catch (e){

}

